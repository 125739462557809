<template>
  <div
    class="challenge"
  >
    <div
      class="challenge__row"
    >
      <a-typography-text
        :strong="true"
      >
        {{ challenge.description }}
      </a-typography-text>
      <span
        v-if="challenge.isTodayReport"
      >
          {{ emojiHelper.getCup() }}
      </span>
    </div>
    <div
      v-if="isNotReachable"
      class="challenge__row"
    >
      <a-button
        @click="handleArchiveClick"
      >
        Скрыть
      </a-button>
    </div>
    <div
      class="challenge__row"
    >
      <a-typography-text
        copyable
      >
          {{ hash }}
      </a-typography-text>
      <a-typography-text>
        До финиша {{ daysForFinish }} {{ wordDeclensionHelper.getDayDeclension(daysForFinish) }}
      </a-typography-text>
    </div>
    <a-typography-text>
      Дата старта: {{ startDate }}
    </a-typography-text>
    <a-typography-text>
      Дата финиша: {{ endDate }}
    </a-typography-text>
    <a-typography-text>
      Всего отчетов в РК: {{ reportsCount }} / {{ totalReportsCount }}
    </a-typography-text>
    <a-typography-text>
      Забрать золото с {{ awardsStartDay }} по {{ awardsEndDay }}
      <LinkTemplate
        text-before="в "
        link-text="Главном чате"
        text-after="."
        @link-click="handleMainChatClick"
      />
    </a-typography-text>
    <a-typography-text>
      Ставка: {{ bet }}
    </a-typography-text>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  PropType,
} from 'vue';
import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import {
  TypographyText,
} from 'ant-design-vue';
import ChallengeHelper from '@/shared/Helpers/ChallengeHelper';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import WordDeclensionHelper from '@/shared/Helpers/WordDeclensionHelper';
import LinkTemplate from '@/shared/Ui/LinkTemplate.vue';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import EmojiHelper from '@/shared/Helpers/EmojiHelper';
import moment from 'moment';

export default defineComponent({
  components: {
    LinkTemplate,
    ATypographyText: TypographyText,
  },
  props: {
    challenge: {
      type: Object as PropType<ChallengeResponseModel>,
      required: true,
    },
    user: {
      type: Object as PropType<UserInfoResponse | null>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const wordDeclensionHelper = WordDeclensionHelper;

    const hash = computed(() => ChallengeHelper.changeChallengeHash(props.challenge.hash as string));

    const daysForFinish = computed(() => ChallengeHelper.getDaysForFinish(props.challenge));

    const startDate = computed(() => ChallengeHelper.getChallengeStartDate(props.challenge));

    const endDate = computed(
      () => ChallengeHelper.getChallengeEndDate(props.challenge).format('DD.MM.YYYY'),
    );

    const reportsCount = computed(() => props.challenge.reports?.length ?? 0);

    const totalReportsCount = computed(() => props.challenge.numberOfReports);

    const awardsStartDay = computed(
      () => ChallengeHelper.getAwardsStartDate(props.challenge),
    );

    const awardsEndDay = computed(() => ChallengeHelper.getAwardsEndDate(props.challenge));

    const bet = computed(() => props.challenge.bet ?? 0);

    async function handleMainChatClick() {
      if (!serviceContainer) {
        return;
      }

      const mainChatLink = await serviceContainer.externalLinkService.getTelegramMainChat();

      const club = props.user?.clubs[0] ?? null;

      if (club && club.mainChatLink) {
        window.open(club.mainChatLink);
      } else {
        window.open(mainChatLink);
      }
    }

    const isNotReachable = computed(() => {
      const reports = props.challenge.reports?.length ?? 0;
      const now = moment();
      const endDate = ChallengeHelper.getChallengeEndDate(props.challenge);
      const diff = endDate.diff(now);
      const days = Math.ceil(diff / 86400000);
      const reportsLeft = (props.challenge.numberOfReports ?? 0) - reports;

      if (diff < 0 && reportsLeft > 0) {
        return true;
      }

      if (reportsLeft <= 0) {
        return false;
      }

      return days + 1 - reportsLeft < 0;
    });

    function handleArchiveClick() {
      emit('archive', props.challenge);
    }

    return {
      isNotReachable,
      hash,
      daysForFinish,
      startDate,
      endDate,
      reportsCount,
      totalReportsCount,
      awardsStartDay,
      awardsEndDay,
      wordDeclensionHelper,
      bet,
      emojiHelper: EmojiHelper,
      handleMainChatClick,
      handleArchiveClick,
    };
  },
});

</script>

<style
  scoped
  lang="scss"
>
.challenge {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  padding: 5px;

  &__row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
  }
}
</style>
