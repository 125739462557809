<template>
  <a-modal
    v-model:open="state.show"
    :centered="true"
    :closable="false"
    title="Улыбка!"
    @cancel="handleClose"
  >
    <p>
      <slot />
    </p>

    <template
      #footer
    >
      <a-button
        key="submit"
        type="primary"
        @click="handleClose"
      >
        Закрыть
      </a-button>
    </template>
  </a-modal>
</template>

<script
  setup
  lang="ts"
>
import {
  defineEmits,
  defineProps,
  onMounted,
  reactive, watch,
  withDefaults,
} from 'vue';

const state = reactive<{ show: boolean }>({
  show: true,
});

const props = withDefaults(
  defineProps<{ show: boolean }>(),
  {
    show: false,
  },
);
const emit = defineEmits(['close']);

onMounted(() => {
  state.show = props.show;
});

watch(() => props.show, (newValue: boolean) => {
  state.show = newValue;
});

function handleClose() {
  state.show = false;

  emit('close');
}
</script>

<style
  scoped
  lang="scss"
>

</style>
