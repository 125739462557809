import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f0a1af9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "freeline-statistics-widget__text-block_column" }
const _hoisted_2 = { class: "freeline-statistics-widget__statistics" }
const _hoisted_3 = {
  key: 0,
  class: "freeline-statistics-widget__archive"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_RedoOutlined = _resolveComponent("RedoOutlined")!
  const _component_ArchiveChallengesWidget = _resolveComponent("ArchiveChallengesWidget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_typography_text, null, {
        default: _withCtx(() => [
          _createTextVNode(" Всего турниров " + _toDisplayString(_ctx.tournamentsCount), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_text, null, {
        default: _withCtx(() => [
          _createTextVNode(" Всего выполненных целей-челленджей " + _toDisplayString(_ctx.challengesCount), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.archiveChallengeList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_a_typography_text, { strong: true }, {
            default: _withCtx(() => [
              _createTextVNode(" История Целленжей, чтобы повторить нажми "),
              _createVNode(_component_RedoOutlined)
            ]),
            _: 1
          }),
          _createVNode(_component_ArchiveChallengesWidget, { challenges: _ctx.archiveChallengeList }, null, 8, ["challenges"])
        ]))
      : _createCommentVNode("", true)
  ]))
}