import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import UpdateMethodInterface from '@/shared/Api/Contract/Api/RPC/Challenge/UpdateMethodInterface';

class UpdateMethod implements UpdateMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(challenge: ChallengeResponseModel): Promise<ChallengeResponseModel> {
    return this.client.instance.post<ChallengeResponseModel>('/v2/challenge/update', challenge)
      .then((response) => response.data);
  }
}

export default UpdateMethod;
