<template>
  <div>
    <ErrorModalWidget
      v-if="state.showErrorModal"
      :show="state.showErrorModal"
      @close="handleModalClose"
    >
      Вы уже зарегистрированы!
    </ErrorModalWidget>
    <ReviewModal
      v-if="state.showReviewModal"
      :show="state.showReviewModal"
      :tournament-template-list="state.tournamentList"
      @apply="handleApplyReview"
      @close="handleCloseReview"
    />
    <div
      class="tournament-widget__buttons"
    >
      <template
        v-if="state.tournamentList.length > 0"
      >
        <template
          v-for="tournamentTemplate in filteredTournamentList"
        >
          <a-button
            v-if="!tournamentTemplate.hide"
            :key="tournamentTemplate.id"
            @click="handleTournamentClick(tournamentTemplate)"
          >
            {{ tournamentTemplate.name }}
          </a-button>
        </template>
        <a-divider
          v-if="filteredTournamentList.length > 0"
        />
        <a-button
          v-if="state.tournamentList.length > 0"
          @click="handleOpenReviewModal()"
        >
          Отзыв о событии
          <LikeOutlined />
        </a-button>
      </template>
      <a-typography-text
        v-else
      >
        Скоро здесь откроется регистрация на турнир
      </a-typography-text>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
} from 'vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import TournamentTemplateModel from '@/shared/Api/Model/RPC/Tournament/TournamentTemplateModel';
import {
  useRouter,
} from 'vue-router';
import {
  LikeOutlined,
} from '@ant-design/icons-vue';
import ReviewModal from '@/widgets/TournamentWidget/Components/ReviewModal.vue';
import ErrorModalWidget from '@/widgets/ErrorModalWidget/ErrorModalWidget.vue';
import TournamentReviewRequestModel
  from '@/shared/Api/Model/RPC/Tournament/TournamentReviewRequestModel';
import { notification } from 'ant-design-vue';

interface State {
  showErrorModal: boolean;
  showReviewModal: boolean;
  tournamentList: TournamentTemplateModel[];
}

export default defineComponent({
  name: 'TournamentWidget',

  components: {
    ReviewModal,
    ErrorModalWidget,
    LikeOutlined,
  },

  setup() {
    const state: State = reactive<State>({
      showErrorModal: false,
      showReviewModal: false,
      tournamentList: [],
    });

    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    const router = useRouter();

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    function handleTournamentClick(tournamentTemplate: TournamentTemplateModel): void {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      if ((tournamentTemplate.challenges ?? []).length === 0) {
        serviceContainer.store.dispatch('addTournamentTemplate', tournamentTemplate);

        router.push({ name: 'tournament-challenge' });

        return;
      }

      state.showErrorModal = true;
    }

    function handleModalClose() {
      state.showErrorModal = false;
    }

    async function handleApplyReview(review: TournamentReviewRequestModel) {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      try {
        await serviceContainer.apiService.rpc.tournament.sendReview.call(review);

        notification.success({
          message: 'Отзыв успешно отправлен!',
          description: 'Спасибо! После проверки отзыв будет опубликован в Главном чате!',
          duration: 30,
        });
      } catch (error) {
        notification.error({
          message: 'Улыбка!',
          description: 'Отчет не был отправлен! Повторите попытку позже.',
          duration: 30,
        });
      }

      state.showReviewModal = false;
    }

    function handleOpenReviewModal() {
      state.showReviewModal = true;
    }

    function handleCloseReview() {
      state.showReviewModal = false;
    }

    async function updateTournamentList() {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      state.tournamentList = await serviceContainer.apiService.rpc.tournament.listActual.call();
    }

    let tournamentUpdateInterval: number;

    onMounted(async () => {
      await updateTournamentList();

      tournamentUpdateInterval = setInterval(updateTournamentList, 5000);
    });

    onUnmounted(() => {
      clearInterval(tournamentUpdateInterval);
    });

    const filteredTournamentList = computed(
      () => state.tournamentList.filter((tournament) => !tournament.hide),
    );

    return {
      state,
      filteredTournamentList,
      handleTournamentClick,
      handleModalClose,
      handleApplyReview,
      handleCloseReview,
      handleOpenReviewModal,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.tournament-widget {
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>
