import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24c790b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tournament-widget__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorModalWidget = _resolveComponent("ErrorModalWidget")!
  const _component_ReviewModal = _resolveComponent("ReviewModal")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_LikeOutlined = _resolveComponent("LikeOutlined")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.state.showErrorModal)
      ? (_openBlock(), _createBlock(_component_ErrorModalWidget, {
          key: 0,
          show: _ctx.state.showErrorModal,
          onClose: _ctx.handleModalClose
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Вы уже зарегистрированы! ")
          ]),
          _: 1
        }, 8, ["show", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.state.showReviewModal)
      ? (_openBlock(), _createBlock(_component_ReviewModal, {
          key: 1,
          show: _ctx.state.showReviewModal,
          "tournament-template-list": _ctx.state.tournamentList,
          onApply: _ctx.handleApplyReview,
          onClose: _ctx.handleCloseReview
        }, null, 8, ["show", "tournament-template-list", "onApply", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.state.tournamentList.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTournamentList, (tournamentTemplate) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (!tournamentTemplate.hide)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: tournamentTemplate.id,
                      onClick: ($event: any) => (_ctx.handleTournamentClick(tournamentTemplate))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(tournamentTemplate.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256)),
            (_ctx.filteredTournamentList.length > 0)
              ? (_openBlock(), _createBlock(_component_a_divider, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.state.tournamentList.length > 0)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleOpenReviewModal()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Отзыв о событии "),
                    _createVNode(_component_LikeOutlined)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(_component_a_typography_text, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode(" Скоро здесь откроется регистрация на турнир ")
            ]),
            _: 1
          }))
    ])
  ]))
}