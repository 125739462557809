import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-84fbaaea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "rating-club-widget__header"
}
const _hoisted_2 = {
  key: 1,
  class: "rating-club-widget__header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_LinkTemplate = _resolveComponent("LinkTemplate")!

  return (_ctx.club)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.club.name) + " ", 1),
        _createVNode(_component_a_button, { onClick: _ctx.handleClubLink }, {
          default: _withCtx(() => [
            _createTextVNode(" Перейти в чат для отчётов ")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => [
            _createTextVNode(" До розыгрыша командной футболки " + _toDisplayString(_ctx.club.name) + " твоему клубу осталось пригласить " + _toDisplayString(_ctx.usersCountText) + " " + _toDisplayString(_ctx.wordDeclensionHelper.getFriendDeclension(_ctx.usersCountText)), 1)
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => [
            _createTextVNode(" Вступи в рейтинговый клуб: ")
          ]),
          _: 1
        }),
        _createVNode(_component_LinkTemplate, {
          "text-before": "см. ",
          "link-text": "Важный раздел",
          "text-after": ", раздел:",
          onLinkClick: _ctx.handleImportantSectionLinkClick
        }, null, 8, ["onLinkClick"]),
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => [
            _createTextVNode(" раздел Как выбрать рейтинговый клуб ")
          ]),
          _: 1
        })
      ]))
}