<template>
  <div
    class="active-challenges-widget"
  >
    <a-button
      @click="goToCrateChallenge"
    >
      ПОСТАВИТЬ ЦЕЛЛЕНЖ
    </a-button>
    <div
      v-if="challengeList.length > 0"
      class="active-challenges-widget__list"
    >
      <ActiveChallenge
        v-for="(challenge, index) in challengeList"
        :key="`activeChallenge${index}`"
        :challenge="challenge"
        :user="user"
        @archive="handleArchive"
      />
    </div>
    <div
      v-else
      class="active-challenges-widget__block"
    >
      <a-typography-text>
        Поставь первую цель-челлендж.
      </a-typography-text>
      <LinkTemplate
        text-before="см. "
        link-text="Важный раздел"
        text-after=", раздел:"
        @link-click="handleImportantSectionLinkClick"
      />
      <a-typography-text>
        Поставить цель.
      </a-typography-text>
    </div>
  </div>
</template>

<script
  lang="ts"
>

import {
  defineComponent,
  inject,
  computed,
  PropType,
} from 'vue';
import {
  useRouter,
} from 'vue-router';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import {
  TypographyText,
} from 'ant-design-vue';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import LinkTemplate from '@/shared/Ui/LinkTemplate.vue';
import ActiveChallenge from '@/widgets/ActiveChallengesWidget/ActiveChallenge.vue';
import ChallengeHelper from '@/shared/Helpers/ChallengeHelper';
import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';

export default defineComponent({
  components: {
    ActiveChallenge,
    LinkTemplate,
    ATypographyText: TypographyText,
  },
  props: {
    user: {
      type: Object as PropType<UserInfoResponse | null>,
      required: true,
    },
  },
  setup(props) {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const router = useRouter();

    const challengeList = computed(() => {
      if (!props.user || props.user.challenges.length === 0) {
        return [];
      }

      return props.user.challenges.filter(
        (challenge) => ChallengeHelper.isChallengeActive(challenge),
      );
    });

    function goToCrateChallenge(): void {
      router.push({ name: 'challenge' });
    }

    async function handleImportantSectionLinkClick() {
      if (!serviceContainer) {
        return;
      }

      const importantSectionLink = await serviceContainer.externalLinkService.getTelegramImportantSection();

      window.open(importantSectionLink);
    }

    async function handleArchive(challenge: ChallengeResponseModel) {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      challenge.archived = true;

      await serviceContainer.apiService.rpc.challenge.update.call(challenge);

      await serviceContainer.store.dispatch('updateUser');
    }

    return {
      challengeList,
      handleArchive,
      goToCrateChallenge,
      handleImportantSectionLinkClick,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.active-challenges-widget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
