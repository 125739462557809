<template>
  <a-modal
    v-model:open="state.show"
    :centered="true"
    :closable="false"
    title="Отзыв"
    class="review-modal"
    @cancel="handle"
  >
    <div
      class="review-modal__content"
    >
      <div
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Выбрать событие
        </a-typography-text>
        <a-select
          :options="options"
          @change="handleSelectChange"
        />
      </div>
      <div
        v-show="state.review.tournamentTemplate &&  window === 'file'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Прикрепить фото
        </a-typography-text>
        <a-upload
          :before-upload="handleBeforeUpload"
          style="width: 100%"
          @remove="handleRemove"
        >
          <a-button
            :disabled="!!state.review.file"
            style="width: 100%"
          >
            Загрузить
            <VerticalAlignBottomOutlined/>
          </a-button>
        </a-upload>
      </div>
      <div
        v-if="state.review.tournamentTemplate && window === 'about'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Напиши пару слов о событии.
        </a-typography-text>
        <a-typography-text>
          Например, ты провел/а классную тренировку по плаванию или пробежал/а на лыжах. Сделал/а
          крутое фото или написал/а стихотворение. Любое действие, которое двинуло тебя вперед -
          подходит. ⬇️
        </a-typography-text>
        <a-textarea
          :maxlength="250"
          v-model:value="state.review.about"
        />
      </div>
      <div
        v-if="state.review.tournamentTemplate && window === 'result'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Принято ✅
        </a-typography-text>
        <a-typography-text>
          Опиши свой результат, итог действия (подходит любой – медаль, место в рейтинге,
          расстояние, темп, количество страниц, шахматных партий, пр) Даже проигрыш подходит, ведь
          он делает тебя сильнее!
        </a-typography-text>
        <a-textarea
          v-model:value="state.review.result"
          :maxlength="250"
        />
      </div>
      <div
        v-if="state.review.tournamentTemplate && window === 'emotions'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Идеально 😍
        </a-typography-text>
        <a-typography-text>
          Поделись, какие эмоции ты испытываешь, хочешь ли повторить?
        </a-typography-text>
        <a-textarea
          v-model:value="state.review.emotions"
          :maxlength="250"
        />
      </div>
      <div
        v-if="state.review.tournamentTemplate && window === 'team'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Ура! Последний шаг перед отправкой: укажи название своей сборной
        </a-typography-text>
        <a-typography-text>
          (например: Бежим к солнцу, Вдарим по року, У Сережи в дзене, пр.)⬇️
        </a-typography-text>
        <a-input
          v-model:value="state.review.team"
        />
      </div>
      <div
        v-if="state.review.tournamentTemplate && window === 'submit'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
          class="w-100"
          :align="'center'"
        >
          Отправить
        </a-typography-text>
      </div>
    </div>
    <template
      #footer
    >
      <div
        v-if="state.review.tournamentTemplate"
        :class="buttonsClassList"
      >
        <div>
          <a-button
            v-if="window === 'submit'"
            key="submit"
            type="primary"
            @click="handleSendReview"
          >
            Отправить
          </a-button>
          <a-button
            v-if="window !== 'file' && window !== 'submit'"
            key="submit"
            type="primary"
            @click="handlePreviousClick"
          >
            Предыдущий
          </a-button>
        </div>
        <div>
          <a-button
            v-if="window !== 'submit'"
            key="submit"
            type="primary"
            :disabled="!state.review[window]"
            @click="handleNextClick"
          >
            Следующий
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script
  setup
  lang="ts"
>
import {
  reactive,
  defineProps,
  defineEmits,
  withDefaults,
  onMounted,
  watch,
  computed,
} from 'vue';
import TournamentTemplateModel from '@/shared/Api/Model/RPC/Tournament/TournamentTemplateModel';
import {
  VerticalAlignBottomOutlined,
} from '@ant-design/icons-vue';

interface State {
  show: boolean;
  review: {
    about: string;
    result: string;
    team: string;
    emotions: string;
    file: File | null;
    tournamentTemplate: TournamentTemplateModel | null;
  };
  window: number;
}

interface Props {
  show: boolean;
  tournamentTemplateList: TournamentTemplateModel[];
}

const emit = defineEmits(['apply', 'close']);

const state = reactive<State>({
  show: false,
  review: {
    about: '',
    result: '',
    team: '',
    emotions: '',
    file: null,
    tournamentTemplate: null,
  },
  window: 0,
});

const props = withDefaults(
  defineProps<Props>(),
  {
    show: false,
  },
);

const windows = [
  'file',
  'about',
  'result',
  'emotions',
  'team',
  'submit',
];

function handleSendReview() {
  emit('apply', { ...state.review });

  state.review.about = '';
  state.review.tournamentTemplate = null;
  state.review.file = null;

  state.show = false;
}

const options = computed(() => props.tournamentTemplateList
  .map((tournamentTemplate) => ({
    value: tournamentTemplate.id,
    label: tournamentTemplate.name,
  })));

function handleSelectChange(id: number): void {
  const tournamentTemplate = props
    .tournamentTemplateList
    .find((tournamentTemplate) => tournamentTemplate.id === id);

  if (!tournamentTemplate) {
    return;
  }

  state.review.tournamentTemplate = tournamentTemplate;
}

function handleBeforeUpload(file: File) {
  state.review.file = file;

  return false;
}

function handleRemove() {
  state.review.file = null;
}

function handle() {
  emit('close');

  state.show = false;
}

onMounted(() => {
  state.show = props.show;
});

watch(() => props.show, (newValue: boolean) => {
  state.show = newValue;
});

function handlePreviousClick() {
  state.window = state.window - 1 < 0 ? 0 : state.window - 1;
}

function handleNextClick() {
  state.window = state.window > windows.length ? state.window : state.window + 1;
}

const window = computed(() => windows[state.window]);

const buttonsClassList = computed(() => [
  'review-modal__buttons',
  window.value === 'submit' ? 'review-modal__buttons_center' : null,
]);

</script>

<style
  scoped
  lang="scss"
>
.review-modal {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    &_center {
      justify-content: center;
    }
  }
}
</style>
