<template>
  <div
    class="archive-challenge"
  >
    <div
      class="archive-challenge__text"
    >
      <a-typography-text>
        {{ challenge.description }}
      </a-typography-text>
    </div>
    <div
      class="archive-challenge__buttons"
    >
      <a-button
        type="default"
        shape="circle"
        :icon="h(RedoOutlined)"
        @click="handleOneClickClick"
      />
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>

import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import {
  defineEmits,
  defineProps,
  h,
} from 'vue';
import {
  TypographyText as ATypographyText,
} from 'ant-design-vue';
import {
  RedoOutlined,
} from '@ant-design/icons-vue';

interface Props {
  challenge: ChallengeResponseModel;
}

const emit = defineEmits(['one-click-click']);
const props = defineProps<Props>();

function handleOneClickClick() {
  emit('one-click-click', props.challenge);
}
</script>

<style
  scoped
  lang="scss"
>
.archive-challenge {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;

  &__buttons {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
</style>
